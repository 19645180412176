import {Box, Center, Heading, Tooltip} from '@chakra-ui/react';

import './styles/skills.css';

import { motion, useAnimation } from 'framer-motion';
import React, {useState, useRef, useEffect} from 'react';



function Skill(){

    return(
        <>
        <div class = 'heading' style={{textAlign: 'center', padding: '1rem 0', width: '100%'}}>
                <Heading color={"#1c1b1b"}
                size={{ base: "2xl", md: "3xl", lg: "4xl" }}
                fontFamily={"Poppins, sans-serif"}
                textShadow={{ base: "1px 1px #b5b6b7", md: "2px 2px #b5b6b7", lg: "2px 2px #b5b6b7" }}
                h={"100%"}
                margin={{ base: "1vh", md: "3vh", lg: "5vh" }}
                _after={{content: "''", 
                display: "block",  
                width: "100px", 
                borderBottom: "3px solid #1f6e90", 
                margin: "auto", 
                marginTop: { base: "10px", md: "20px", lg: "30px" } }}
                _hover={{
                    // color: "#501a1f",
                    // textShadow:"1px 1px #000",
                    _after:{
                        width: { base: "9%", md: "9%", lg: "10%" },
                        borderBottom: "3px solid #b5b6b7"
                    }
                }}>Skills</Heading>
        </div>

        <div className='container1' id = 'skills-section' >
            
        </div>
           

            
        </>
    )

}
export default Skill;
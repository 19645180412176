import {Heading, IconButton} from '@chakra-ui/react';
import './styles/header.css';
import { useRef, useState } from 'react';
import {motion} from 'framer-motion';
import {HamburgerIcon, CloseIcon} from '@chakra-ui/icons';
import { Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, Box } from '@chakra-ui/react';

function Header({scrollToSection, about, project, skills, education, experience, contact}) {

    const [isOpen, setIsOpen] = useState(false);
        //031026
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const headingVariants = {
        hidden: { x: '100%', opacity: 0 },
        visible: { x: 0, opacity: 1 }
    };


    return(
        <>
        <header className='app-header'>
            <div className="eshi-logo">
                <Heading 
                size={{ base: "md", md: "lg", lg: "xl", xl: "xl" }}
                 w={"10vw"}    
                 _hover={{color: "#1f6e90" }}
                 transition="all 0.3s ease">EshI A</Heading>
            </div>

            <div className='nav'>
                <ul>
                    <li className='link'> <Heading size={{ base: "sm", md: "md", lg: "lg" }} fontFamily={"Oswald, sans-serif"} onClick={() => scrollToSection(about)}>About <span className="coloured-text">Me</span></Heading> </li>
                    <li className='link'> <Heading size={{ base: "sm", md: "md", lg: "lg" }} fontFamily={"Oswald, sans-serif"}  onClick={() => scrollToSection(project)} _hover={{color: "#1f6e90"}} >Projects</Heading></li>
                    <li className='link'> <Heading size={{ base: "sm", md: "md", lg: "lg" }} fontFamily={"Oswald, sans-serif"}  onClick={() => scrollToSection(skills)}  _hover={{color: "#1f6e90"}}> Skills</Heading></li>
                    <li className='link'> <Heading size={{ base: "sm", md: "md", lg: "lg" }} fontFamily={"Oswald, sans-serif"}  onClick={() => scrollToSection(education)} _hover={{color: "#1f6e90"}}>Education</Heading></li>
                    {/* <li className='link'> <Heading size={{ base: "sm", md: "md", lg: "lg" }}  onClick={() => scrollToSection(experience)} >Experience</Heading></li> */}
                    
                  <li className='link'> <Heading size={{ base: "sm", md: "md", lg: "lg" }} fontFamily={"Oswald, sans-serif"}  onClick={() => scrollToSection(contact)}>Contact <span className="coloured-text">Me</span></Heading></li>
                </ul>
            </div>
            

            

        </header>
        <Drawer isOpen={isOpen}
            placement='right'
            onClose={toggleMenu}
            size={"md"}
            >

            <DrawerOverlay />
                <DrawerContent bg="#FBF9F3" color="white">
                    <DrawerHeader borderBottomWidth="1px" 
                    borderBottomColor="#1c1b1b"
                    color=" #1c1b1b" 
                    textAlign={"center"}
                    _hover={{color: "#1F6E90"}}
                    fontSize={"6xl"}
                    textShadow={"1px 1px #000"}
                    fontFamily={"LORA"}>menu</DrawerHeader>
                    <DrawerBody>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <motion.div initial={{ x: '100%', opacity: 0 }} animate="visible" variants={headingVariants} transition={{ delay: 0.1, type: 'spring', stiffness: 300 }} whileHover={{ scale: 1.1 }}>
                            <Heading color={" #1c1b1b"} size={"lg"} padding={".5rem"} fontSize={"4xl"} fontFamily={"Oswald, sans-serif"} onClick={() => { scrollToSection(about); toggleMenu(); }}>About <span class = "coloured-text">ME</span></Heading>
                            </motion.div>

                            <motion.div initial={{ x: '-100%', opacity: 0 }} animate="visible" variants={headingVariants} transition={{ delay: 0.5, type: 'spring', stiffness: 300 }} whileHover={{ scale: 1.1 }}>
                            <Heading color={" #1c1b1b"} size={"lg"} padding={".5rem"} fontSize={"4xl"} fontFamily={"Oswald, sans-serif"} _hover={{color: "#1f6e90"}} onClick={() => { scrollToSection(project); toggleMenu(); }}>Projects</Heading>
                            </motion.div>


                            <motion.div initial={{ x: '100%', opacity: 0 }} animate="visible" variants={headingVariants} transition={{ delay: 1.0, type: 'spring', stiffness: 300 }} whileHover={{ scale: 1.1 }}>
                            <Heading color={" #1c1b1b"} size={"lg"} padding={".5rem"} fontSize={"4xl"} fontFamily={"Oswald, sans-serif"} _hover={{color: "#1f6e90"}} onClick={() => { scrollToSection(skills); toggleMenu(); }}>Skills</Heading>
                            </motion.div>

                            <motion.div initial={{ x: '-100%', opacity: 0 }} animate="visible" variants={headingVariants} transition={{ delay: 1.5, type: 'spring', stiffness: 300 }} whileHover={{ scale: 1.1 }}>
                            <Heading color={" #1c1b1b"} size={"lg"} padding={".5rem"} fontSize={"4xl"} fontFamily={"Oswald, sans-serif"} _hover={{color: "#1f6e90"}} onClick={() => { scrollToSection(education); toggleMenu(); }}>Education</Heading>
                            </motion.div>

                            {/* <Heading size={"lg"} fontSize={"5xl"} fontFamily={"Pathway Gothic One"} _hover={{color: "#501a1f"}} onClick={() => { scrollToSection(experience); toggleMenu(); }}>Experience</Heading> */}
                            <motion.div initial={{ x: '100%', opacity: 0 }}animate="visible" variants={headingVariants} transition={{ delay: 2.0, type: 'spring', stiffness: 300 }} whileHover={{ scale: 1.1 }}>
                            <Heading color={" #1c1b1b"} size={"lg"} padding={".5rem"} fontSize={"4xl"} fontFamily={"Oswald, sans-serif"} onClick={() => { scrollToSection(contact); toggleMenu(); }}>Contact <span class = "coloured-text">ME</span></Heading>
                            </motion.div>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            
        </Drawer>

        <Box 
            as={motion.div}
            initial={{ x: '-100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 2, delay: 0.5 }} >
            {/* <motion.div
            initial={{ x: '-100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}> */}
        
            <IconButton icon={isOpen ? <CloseIcon/> : <HamburgerIcon/>} 
                    onClick={toggleMenu}
                    aria-label='Toggle Navigation'
                    backgroundColor={"#1c1b1b"}
                    // border={"2.5px solid #f2e2d2"}
                    color={"white"}
                    display={{ base: "flex", sm: "flex", md: "none" }}
                    position={"fixed"}
                    bottom={"55px"}
                    right={"20px"}
                    zIndex={1500}
                    transition="background-position 0.5s ease-in-out"
                    // boxShadow={"1.5px 2px #f2e2d2"}
                    _hover={{backgroundColor: "#410b0b", transform: "scale(1.08)", boxShadow: "5px 5px 7px #000000"}}
                    />
            {/* </motion.div> */}

        </Box>

        </>
    )

}

export default Header;
import {ChakraProvider, Box, Spinner, Text} from '@chakra-ui/react';
import { useRef, useEffect, useState  } from 'react';
import React from 'react';
import Header from './Header';
import About from './About';
import Projects from './Projects';
import Skills from './Skills';
import Education from './Education';
import Experience from './Experience';
import ContactForm from './ContactForm';
import TOPOLOGY from 'vanta/dist/vanta.topology.min';
import * as THREE from 'three'; // Vanta.js requires THREE
import p5 from 'p5'; // Vanta.js requires p5


import logo from './logo.svg';
import './App.css';


/**
 * The main App component.
 *
 * @return {JSX.Element} The rendered App component.
 */
function App() {

  const [loading, setLoading] = useState(false);
      
  // References to the sections of the page.
  const about = useRef(null);
  const project = useRef(null);
  const skills = useRef(null);
  const education = useRef(null);
  const experience = useRef(null);
  const contact = useRef(null);


  /**
   * Scrolls the window to the specified section smoothly.
   *
   * @param {object} elementRef - A reference to the DOM element to scroll to.
   * @return {void} No return value.
   */
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth"
    })
  }

  return (
    <ChakraProvider >
        <>
        <div className="App">      
        {/* Header component */}
        <Header scrollToSection={scrollToSection}
              about={about} 
              project={project} 
              skills={skills} 
              education={education} 
              experience={experience} 
              contact={contact}  />
        {/* About component */}
        <div ref={about} className="about">
          <About contactRef={contact}/>
        </div>
        {/* Projects component */}
        <div ref={project} className="projects">
          <Projects/>
        </div>
        {/* Skills component */}
        <div ref={skills} className="skills">
          <Skills/>
        </div>
        {/* Education component */}
        <div ref={education} className="education">
          <Education/>
        </div>
        {/* Experience component */}
        <div ref={experience} className="experience">
          <Experience/>
        </div>
        {/* Contact component */}
        <div ref={contact} className="contact">
          <ContactForm/>
        </div>
      </div>
        </>
      
    </ChakraProvider>
  );
}

export default App;
